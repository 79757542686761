<!--
   Created by Terence on 2024/6/23 - 15:24
-->
<template>
  <DuTheme name="linjie">
    <DuNoticeBar
      v-if="ltInfo.cantParticipateReason"
      link-text="我参与的"
      :text="ltInfo.cantParticipateReason"
      link-icon="arrow-heavy-right"
      color="default"
      @link-click="handleToMyParticipated"
    />
    <div class="actions" v-if="actionConfig">
      <div class="actions-notice" v-if="actionConfig.tipsInfo">
        <DuNoticeBar
          v-bind="actionConfig.tipsInfo"
          @link-click="actionConfig?.tipsInfo?.action"
        />
      </div>
      <div class="actions-menus" v-if="actionConfig.menus?.length">
        <div
          class="actions-menus-item"
          v-for="(item, index) in actionConfig.menus"
          :key="index"
          @click="item.action"
        >
          <du-icon :name="item.icon" :extStyle="{ fontSize: '5.333333333333334rem' }" />
          <div class="actions-menus-item-text">{{ item.name }}</div>
        </div>
      </div>

      <div
        :class="`actions-btns actions-menus-${actionConfig.menus?.length}`"
        v-if="actionConfig.btns?.length"
      >
        <template v-for="(item, index) in actionConfig.btns" :key="index">
          <DuButton
            v-if="item.customType === 'pay'"
            v-bind="item"
            size="medium"
            @click="item.action"
          >
            <div class="btn-pay">
              <div class="btn-pay-title">立即支付</div>
              <div class="btn-pay-subtitle">
                剩余
                <CountDown
                  @handleRefreshActions="initActionConfig"
                  :expireTime="ltInfo?.orderInfo?.payExpireTime"
                />
              </div>
            </div>
          </DuButton>
          <DuButton v-else v-bind="item" size="medium" @click="item.action">
            {{ item.name }}
          </DuButton>
        </template>
      </div>

      <WinnerPopup v-model="isShowWinnerPopup" />
      <RequirementsPopup v-model="isShowRequirementsPopup" />
    </div>
  </DuTheme>
</template>

<script setup>
import {
  ref,
  onMounted,
  inject,
  computed,
  onUnmounted,
  defineProps,
  defineExpose,
  getCurrentInstance,
} from "vue";
import { DuTheme, DuButton, DuPopup, DuIcon, DuNoticeBar } from "dangoui";
import {
  showFailToast,
  showSuccessToast,
  showLoadingToast,
  closeToast,
  showDialog,
  showConfirmDialog,
} from "vant";
import CountDown from "./CountDown.vue";
import WinnerPopup from "./WinnerPopup.vue";
import RequirementsPopup from "./RequirementsPopup.vue";
import { storageKeys } from "@/constant/storage";
import {
  LIMIT_TYPE,
  LOTTERY_ORDER_STATE,
  PRIZE_TYPE,
} from "@/constant/lottery";
import { getQuery } from "@/utils/url";
import { invokeAppByYYB } from "@/utils/mob";
import LotteryApi from "@/apis/lottery";
import { REQUEST_OK } from "@/constant/network";
import { openPaymentWindow } from "@/utils/payment";
import { SPEND_MONEY_TYPE } from "@/constant/payment";
import { LOTTERY_STATE } from "@/constant/lottery";
import dayjs from "dayjs";
import jb from "@frontend/effuse";
const instance = getCurrentInstance();

const ltInfo = inject("ltInfo");
const isPublisher = inject("isPublisher");
const handleRefreshPage = inject("handleRefreshPage");

const actionConfig = ref({
  menus: null,
  btns: null,
  tipsInfo: null,
});
const isShowRequirementsPopup = ref(false); // TODO use this
// const isShowRequirementsPopup = ref(true);
const isShowWinnerPopup = ref(false); // TODO use this
// const isShowWinnerPopup = ref(true);

const isCustomPrize = computed(() => {
  return ltInfo.value?.prizeType === PRIZE_TYPE.CUSTOM;
});

const hasReachedDeadlineHourAgo = computed(() => {
  const now = dayjs();
  const deadline = dayjs(parseInt(ltInfo.value?.drawTime));
  console.log("deadline", deadline);

  const diff = now.diff(deadline, "hour");
  return diff >= -1;
});

// const props = defineProps({
//   isPublisher: {
//     type: Boolean,
//     default: false,
//   },
// });

const handleManage = () => {
  invokeAppByYYB({
    targetPath: `trade/raffle/my-manager`,
    params: {},
  });
};

onMounted(() => {
  console.log(`${window.location.origin}/share-prove?id=${ltInfo.value.id}`);
});
const handleDelete = async () => {
  showConfirmDialog({
    title: "",
    message: "删除后此抽选不可恢复",
  })
    .then(async () => {
      const { id } = getQuery();
      showLoadingToast();
      const res = await LotteryApi.deleteEvent({ id });

      if (res.code !== REQUEST_OK) {
        showFailToast(`删除失败:${res.message}`);
        return;
      }

      setTimeout(() => {
        showSuccessToast("删除成功");
      }, 300);
      handleRefreshPage();
    })
    .catch(() => {
      // on cancel
    });
};

const handleShowErrorReason = () => {
  showDialog({
    title: `违规原因：${ltInfo.value.illegalReason || "暂无原因"}`,
    message: "本抽选因违规截止后不会开奖，若有需要请重新发布抽选",
    confirmButtonText: "我知道了",
  });
};

const handleEdit = () => {
  const { id } = getQuery();
  invokeAppByYYB({
    targetPath: `trade/raffle/create`,
    params: {
      id,
    },
  });
};

const handleShowWinners = () => {
  isShowWinnerPopup.value = true;
};

const handleForceShowWinners = () => {
  handleShowWinners();
};

const handleShowShareProve = () => {
  const tempUrl = `${window.location.origin}/share-prove?id=${ltInfo.value.id}`;
  const encodedUrl = encodeURIComponent(tempUrl);
  if (window.isInFlutter) {
    jb.navigateTo({
      url: `congress://hybrid?url=${encodedUrl}`,
    });
  } else {
    window.open(tempUrl);
  }
};

const handleShowOrder = () => {
  if (isPublisher.value) {
    invokeAppByYYB({
      targetPath: `trade/order/my-sold`,
      params: {},
    });
  } else {
    invokeAppByYYB({
      targetPath: `order/${ltInfo.value?.orderInfo?.orderId}`,
      params: {},
    });
  }
};

const handleJoin = async () => {
  if (ltInfo.value.needUploadVoucher) {
    handleShowShareProve();
    return;
  }

  const { id } = getQuery();
  showLoadingToast();
  const res = await LotteryApi.joinEvent({ lotteryId: id });

  if (res.code !== REQUEST_OK) {
    showFailToast(`参与失败:${res.message}`);
    return;
  }

  setTimeout(() => {
    showSuccessToast("参与成功");
    // 2025 春节活动
  }, 300);
  handleRefreshPage();
};

const handleFollow = () => {
  isShowRequirementsPopup.value = true;
};

const handleQuit = async () => {
  showConfirmDialog({
    title: "",
    message: ltInfo.value.needUploadVoucher
      ? "取消后你可重新参与本抽选,截止时间开奖前1小时"
      : "取消参与此抽选？",
  })
    .then(async () => {
      const { id } = getQuery();
      showLoadingToast();
      const res = await LotteryApi.quitEvent({ lotteryId: id });

      if (res.code !== REQUEST_OK) {
        showFailToast(`取消参与失败:${res.message}`);
        return;
      }

      setTimeout(() => {
        showSuccessToast("取消参与成功");
      }, 300);
      handleRefreshPage();
    })
    .catch(() => {
      // on cancel
    });
};

const handlePay = () => {
  const tempOrderId = ltInfo.value?.orderInfo?.orderId;

  if (window.isInFlutter) {
    invokeAppByYYB({
      targetPath: `pay`,
      params: {
        orderId: tempOrderId,
        relatedType: "commission",
      },
    });
  } else {
    openPaymentWindow({
      orderId: tempOrderId,
      cbUrl: window.location.href,
      cbUrlQuery: `spendMoneyType=${SPEND_MONEY_TYPE.C2C_LOTTERY_PAY}`,
    });
  }
};

const handleToApp = () => {
  invokeAppByYYB({
    targetPath: `hybrid`,
    params: {
      url: window.location.href,
    },
  });
};

const PUBLISHER_ACTIONS = {
  // 针对h5
  H5_ONLY: {
    menus: null,
    btns: [
      {
        name: "请去App操作",
        action: handleToApp,
        full: true,
      },
    ],
    tipsInfo: null,
  },
  ILLEGAL: {
    menus: [
      {
        name: "抽选管理",
        action: handleManage,
        icon: "all",
      },
      {
        name: "删除",
        action: handleDelete,
        icon: "delete",
      },
    ],
    btns: [],
    tipsInfo: {
      color: "error",
      text: "此抽选有违规内容，已终止 ",
      linkText: "查看原因",
      linkIcon: "arrow-right",
      action: handleShowErrorReason,
    },
  },
  PROCESSING: {
    menus: [
      {
        name: "抽选管理",
        action: handleManage,
        icon: "all",
      },
      {
        name: "删除",
        action: handleDelete,
        icon: "delete",
      },
    ],
    btns: [
      {
        name: "编辑",
        action: handleEdit,
        type: "outline",
        full: true,
      },
    ],
    tipsInfo: null,
  },
  DRAWING: {
    menus: [
      {
        name: "抽选管理",
        action: handleManage,
        icon: "all",
      },
    ],
    btns: [
      {
        name: "正在开奖中",
        disabled: true,
        disabledType: "default",
        action: handleEdit,
      },
    ],
    tipsInfo: null,
  },
  DRAWN: {
    menus: null,
    btns: [
      {
        name: "中奖名单",
        full: true,
        action: handleShowWinners,
        type: "outline",
      },
      {
        name: "查看订单",
        full: true,
        extStyle: { marginLeft: '2.1333333333333333rem' },
        action: handleShowOrder,
        type: "outline",
      },
    ],
    tipsInfo: null,
  },
  ABORT: {
    menus: [
      {
        name: "抽选管理",
        action: handleManage,
        icon: "all",
      },
      {
        name: "删除",
        action: handleDelete,
        icon: "delete",
      },
    ],
    btns: [],
    tipsInfo: {
      color: "error",
      text: "此抽选有违规内容，已终止 ",
      linkText: "查看原因",
      linkIcon: "arrow-right",
      action: handleShowErrorReason,
    },
  },
};

const PUBLISHER_ACTIONS_FORK = {
  PROCESSING_STARTED: {
    menus: [
      {
        name: "抽选管理",
        action: handleManage,
        icon: "all",
      },
    ],
    btns: [
      {
        name: "已有人参与，暂不可删改",
        disabled: true,
        disabledType: "default",
        action: handleEdit,
        full: true,
      },
    ],
    tipsInfo: null,
  },
  CUSTOM_DRAWN: {
    menus: null,
    btns: [
      {
        name: "中奖名单",
        full: true,
        action: handleShowWinners,
        type: "outline",
      },
    ],
    tipsInfo: {
      color: "primary",
      text: "请联系中奖者发放奖品",
      linkText: "",
      linkIcon: "",
    },
  },
};

const BUYER_ACTIONS = {
  // 针对h5
  H5_ONLY: {
    menus: null,
    btns: [
      {
        name: "请去App操作",
        action: handleToApp,
        full: true,
      },
    ],
    tipsInfo: null,
  },
  PROCESSING: {
    menus: null,
    btns: [
      {
        name: "立即参与",
        action: handleJoin,
        full: true,
      },
    ],
    tipsInfo: null,
  },
  DRAWING: {
    menus: null,
    btns: [
      {
        name: "正在开奖中，请稍候",
        full: true,
        disabled: true,
        disabledType: "temp",
      },
    ],
    tipsInfo: null,
  },
  DRAWN: {
    menus: null,
    btns: null,
    tipsInfo: null,
  },
  ABORT: {
    menus: null,
    btns: [
      {
        name: "因触发违规处理，本次抽选已终止",
        full: true,
        disabled: true,
      },
    ],
    tipsInfo: null,
  },
};

const BUYER_ACTIONS_FORK = {
  PROCESSING_CAN_JOIN: {
    menus: null,
    btns: [
      {
        name: "立即参与",
        action: handleJoin,
        full: true,
      },
    ],
    tipsInfo: null,
  },
  PROCESSING_CAN_JOIN_SHARE_PROVE_DEADLINE_REACHED: {
    menus: null,
    btns: [
      {
        name: "查看分享证明",
        action: handleShowShareProve,
        full: true,
      },
    ],
    tipsInfo: null,
  },
  PROCESSING_FORBID_JOIN: {
    menus: null,
    btns: [
      {
        name: "立即参与",
        full: true,
        disabled: true,
        type: "outline",
      },
    ],
    tipsInfo: null,
  },
  PROCESSING_CAN_JOIN_SHARE_PROVE_HOUR_AGO: {
    menus: null,
    btns: [
      {
        name: "取消参与",
        full: true,
        action: handleQuit,
        type: "outline",
      },
      {
        name: "查看分享证明",
        action: handleShowShareProve,
        full: true,
        extStyle: { marginLeft: '2.1333333333333333rem' },
      },
    ],
    tipsInfo: null,
  },
  PROCESSING_NEED_FOLLOW: {
    menus: null,
    btns: [
      {
        name: "参与抽选",
        action: handleFollow,
        full: true,
      },
    ],
    tipsInfo: null,
  },
  PROCESSING_JOINED: {
    menus: null,
    btns: [
      {
        // name: "参与成功！等待开奖",
        name: "取消参与",
        full: true,
        action: handleQuit,
        // disabled: true,
        // disabledType: "temp",
      },
    ],
    tipsInfo: null,
  },
  DRAWN_WINNER_INIT_PAY: {
    menus: null,
    btns: [
      {
        name: "中奖名单",
        action: handleShowWinners,
        full: true,
        type: "outline",
      },
      {
        name: "订单生成中",
        extStyle: { marginLeft: '2.1333333333333333rem' },
        full: true,
        disabled: true,
        disabledType: "temp",
      },
    ],
    tipsInfo: {
      color: "primary",
      text: "恭喜你中奖了！请尽快支付哦，超时视为自动放弃",
      linkText: "",
      linkIcon: "",
    },
  },
  DRAWN_WINNER_WAIT_PAY: {
    menus: null,
    btns: [
      {
        name: "中奖名单",
        action: handleShowWinners,
        full: true,
        type: "outline",
      },
      {
        customType: "pay",
        name: "立即支付",
        extStyle: { marginLeft: '2.1333333333333333rem' },
        action: handlePay,
        full: true,
      },
    ],
    tipsInfo: {
      color: "primary",
      text: "恭喜你中奖了！请尽快支付哦，超时视为自动放弃",
      linkText: "",
      linkIcon: "",
    },
  },
  CUSTOM_DRAWN_WINNER_WAIT_PAY: {
    menus: null,
    btns: [
      {
        name: "中奖名单",
        action: handleShowWinners,
        full: true,
        type: "outline",
      },
    ],
    tipsInfo: {
      color: "primary",
      text: "恭喜你中奖了！请联系发布者进行兑奖",
      linkText: "",
      linkIcon: "",
    },
  },
  CUSTOM_DRAWN_NOT_WINNER: {
    menus: null,
    btns: [
      {
        name: "中奖名单",
        action: handleShowWinners,
        full: true,
        type: "outline",
      },
    ],
    tipsInfo: null,
  },
  DRAWN_WINNER_FINISHED_PAY: {
    menus: null,
    btns: [
      {
        name: "中奖名单",
        action: handleShowWinners,
        full: true,
        type: "outline",
      },
      {
        name: "查看订单",
        action: handleShowOrder,
        extStyle: { marginLeft: '2.1333333333333333rem' },
        full: true,
        type: "outline",
      },
    ],
    tipsInfo: null,
  },
  DRAWN_NOT_WINNER: {
    menus: null,
    btns: [
      {
        name: "中奖名单",
        action: handleShowWinners,
        full: true,
        type: "outline",
      },
    ],
    tipsInfo: null,
  },
};

const handleFirstEnterWhenDrawn = () => {
  const { id } = getQuery();
  const tempShowedWinnerPopup = localStorage.getItem(
    `${storageKeys.SHOWED_WINNER_POPUP}:${id}`
  );
  if (!tempShowedWinnerPopup) {
    isShowWinnerPopup.value = true;
    localStorage.setItem(`${storageKeys.SHOWED_WINNER_POPUP}:${id}`, "YES");
  }
};

const handleToMyParticipated = () => {
  jb.navigateTo({
    url: `congress://trade/raffle/my-manager?index=1`,
  });
};

const initActionConfig = () => {
  const { tempDebug } = getQuery();
  console.log("ltInfo===", ltInfo.value);
  // const buyerConfig = {};
  // const publisherConfig = {};
  let tempConfig = {};
  // menus btns tipsInfo

  if (isPublisher.value) {
    tempConfig = PUBLISHER_ACTIONS[ltInfo.value.state];
    if (
      ltInfo.value.state === LOTTERY_STATE.PROCESSING &&
      +ltInfo.value.participateCount
    ) {
      // 进行中 - 已有人参与
      Object.assign(tempConfig, PUBLISHER_ACTIONS_FORK.PROCESSING_STARTED);
    }
    if (ltInfo.value.state === LOTTERY_STATE.DRAWN) {
      if (isCustomPrize.value) {
        Object.assign(tempConfig, PUBLISHER_ACTIONS_FORK.CUSTOM_DRAWN);
      }
      handleFirstEnterWhenDrawn();
    }

    // 非app环境, 只给一个按钮
    if (!window.isInFlutter && !tempDebug) {
      tempConfig = PUBLISHER_ACTIONS.H5_ONLY;
    }
  } else {
    tempConfig = BUYER_ACTIONS[ltInfo.value.state];
    if (ltInfo.value.state === LOTTERY_STATE.PROCESSING) {
      if (
        !ltInfo.value.canParticipate &&
        ltInfo.value.participateLimitType === "ONLY_FANS"
      ) {
        // 进行中 - 需要关注才能参与
        Object.assign(tempConfig, BUYER_ACTIONS_FORK.PROCESSING_NEED_FOLLOW);

        // 春节活动糊屎代码 --> 已近加入了一个春节抽选
        if (
          ltInfo.value.hasJoinedOtherSpecialLottery ||
          ltInfo.value.isArrivedCantJoinTime
        ) {
          Object.assign(tempConfig, BUYER_ACTIONS_FORK.PROCESSING_FORBID_JOIN);
        }
      } else if (!ltInfo.value.canParticipate && !ltInfo.value.isParticipated) {
        // 进行中 - 不能参与
        Object.assign(tempConfig, BUYER_ACTIONS_FORK.PROCESSING_FORBID_JOIN);
      } else if (ltInfo.value.canParticipate && !ltInfo.value.isParticipated) {
        // 进行中 - 且尚未参与
        Object.assign(tempConfig, BUYER_ACTIONS_FORK.PROCESSING_CAN_JOIN);

        // 春节活动糊屎代码  needUploadVoucher --> 是春节活动
        if (
          ltInfo.value.needUploadVoucher &&
          !ltInfo.value?.publisher?.isMyFollowing
        ) {
          Object.assign(tempConfig, BUYER_ACTIONS_FORK.PROCESSING_NEED_FOLLOW);
        }
      } else if (
        ltInfo.value.isParticipated &&
        ltInfo.value.needUploadVoucher &&
        !ltInfo.value.isArrivedCantJoinTime
      ) {
        console.log(
          "PROCESSING_CAN_JOIN_SHARE_PROVE_HOUR_AGO",
          ltInfo.value.isArrivedCantJoinTime
        );

        // 进行中 - 已参与 - 需要上传分享证明 - 没到 1h
        Object.assign(
          tempConfig,
          BUYER_ACTIONS_FORK.PROCESSING_CAN_JOIN_SHARE_PROVE_HOUR_AGO
        );
      } else if (
        ltInfo.value.isParticipated &&
        ltInfo.value.needUploadVoucher &&
        ltInfo.value.isArrivedCantJoinTime
      ) {
        // 进行中 - 已参与 - 需要上传分享证明 - 已到 1h
        Object.assign(
          tempConfig,
          BUYER_ACTIONS_FORK.PROCESSING_CAN_JOIN_SHARE_PROVE_DEADLINE_REACHED
        );
      } else if (ltInfo.value.isParticipated) {
        // 参与了 等待开奖
        Object.assign(tempConfig, BUYER_ACTIONS_FORK.PROCESSING_JOINED);
      }
    }

    if (ltInfo.value.state === LOTTERY_STATE.DRAWN) {
      if (ltInfo.value.isWinner) {
        const tempNowTime = +new Date();
        const tempOrderState = ltInfo.value?.orderInfo?.state;
        if (
          tempOrderState === LOTTERY_ORDER_STATE.PAID ||
          tempNowTime >= +ltInfo.value?.orderInfo?.payExpireTime
        ) {
          Object.assign(
            tempConfig,
            BUYER_ACTIONS_FORK.DRAWN_WINNER_FINISHED_PAY
          );
        } else if (tempOrderState === LOTTERY_ORDER_STATE.INIT) {
          // 订单生成中
          Object.assign(tempConfig, BUYER_ACTIONS_FORK.DRAWN_WINNER_INIT_PAY);
        } else {
          // 待付款
          Object.assign(tempConfig, BUYER_ACTIONS_FORK.DRAWN_WINNER_WAIT_PAY);
        }
      } else {
        Object.assign(tempConfig, BUYER_ACTIONS_FORK.DRAWN_NOT_WINNER);
      }
      if (isCustomPrize.value) {
        if (ltInfo.value.isWinner) {
          Object.assign(
            tempConfig,
            BUYER_ACTIONS_FORK.CUSTOM_DRAWN_WINNER_WAIT_PAY
          );
        } else {
          Object.assign(tempConfig, BUYER_ACTIONS_FORK.CUSTOM_DRAWN_NOT_WINNER);
        }
      }
      if (ltInfo.value.isParticipated) {
        handleFirstEnterWhenDrawn();
      }
    }

    // 非app环境, 只给一个按钮
    if (!window.isInFlutter && !tempDebug) {
      tempConfig = BUYER_ACTIONS.H5_ONLY;
    }
    // debug
    Object.assign(
      tempConfig,
      BUYER_ACTIONS_FORK.PROCESSING_CAN_JOIN_SHARE_PROVE_HOUR_AGO
    );
  }

  // actionConfig.value = JSON.parse(JSON.stringify(tempConfig));
  // actionConfig.value = tempConfig;
  actionConfig.value = { ...actionConfig.value, ...tempConfig };
  // instance.proxy.$forceUpdate();
};

onMounted(() => {
  initActionConfig();
});

defineExpose({
  handleForceShowWinners,
  initActionConfig,
});
</script>

<style lang="scss" scoped>
.actions {
  position: relative;
  padding: 10rpx 30rpx;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2rpx solid #f1f1f1;
  //background-color: skyblue;
  //box-shadow: 0 -2rpx 0 0 #E1E1E5;
  &-notice {
    z-index: 30;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-100%);
    //pointer-events: none;
  }
  &-menus {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &-text {
        margin-top: 4rpx;
        color: #000;
        text-align: center;
        font-size: 24rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 36rpx; /* 150% */
      }
    }
  }
  &-btns {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-pay {
      &-title {
        color: #fff;
        font-size: 32rpx;
        font-style: normal;
        font-weight: 500;
        //line-height: 48rpx; /* 150% */
        line-height: 40rpx; /* 150% */
      }
      &-subtitle {
        color: #fff;
        text-align: center;
        font-size: 20rpx;
        font-style: normal;
        font-weight: 400;
        //line-height: 22rpx; /* 110% */
        line-height: 20rpx; /* 110% */
      }
    }
  }
  // 0个menu按钮
  &-menus-0 {
    flex: 1;
  }
  // 1个menu按钮
  &-menus-1 {
    flex: 0 0 546rpx;
    margin-left: 48rpx;
  }
  // 2个menu按钮
  &-menus-2 {
    flex: 0 0 450rpx;
    margin-left: 48rpx;
  }
}
</style>
